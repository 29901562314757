// @file Helper functions for wall collections.
import { captureMessage } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { currentHostname } from '@@/bits/location'
import { NATIVE_HOST } from '@@/bits/url'
import { vSet } from '@@/bits/vue'
import { shouldLog } from '@@/pinia/plugin/logger'
import type { FolderId, Id, UserGroupId } from '@@/types'
import type { CollectionKey, CollectionsSubtree, CollectionsTree, WallsFilter } from '@@/types/collections'
import { CollectionKeyTypes } from '@@/types/collections'

export enum WallDateSortProperty {
  LastPresentAt = 'lastPresentAt',
  CreatedAt = 'createdAt',
  TrashedAt = 'trashedAt',
  UpdatedAt = 'updatedAt',
}

export enum WallSortParamValue {
  LastPresentAt = '-last_present_at',
  CreatedAt = '-created_at',
  TrashedAt = '-trashed_at',
  UpdatedAt = '-updated_at',
}

const WALL_FILTER_SORT_PROPERTIES: Partial<Record<WallsFilter, WallDateSortProperty>> = {
  combined_recents: WallDateSortProperty.LastPresentAt,
  gallery: WallDateSortProperty.CreatedAt,
  trashed: WallDateSortProperty.TrashedAt,
}

const WALL_FILTER_SORT_ATTRIBUTES: Partial<Record<WallsFilter, WallSortParamValue>> = {
  combined_recents: WallSortParamValue.LastPresentAt,
  gallery: WallSortParamValue.CreatedAt,
  trashed: WallSortParamValue.TrashedAt,
}

export const getCollectionDateSortParamValue = (collectionKey: CollectionKey): WallSortParamValue => {
  return WALL_FILTER_SORT_ATTRIBUTES[collectionKey.indexKey] ?? WallSortParamValue.UpdatedAt
}

export const getCollectionWallDateSortProperty = (collectionKey: CollectionKey): WallDateSortProperty => {
  return WALL_FILTER_SORT_PROPERTIES[collectionKey.indexKey] ?? WallDateSortProperty.UpdatedAt
}

export const OLD_LIBRARY_ACCOUNT_FILTERS: WallsFilter[] = [
  'all',
  'made',
  'private_walls',
  'templates',
  'archived',
  'trashed',
]
export const LIBRARY_ACCOUNT_FILTERS: WallsFilter[] = [
  'combined_recents',
  'all',
  'made',
  'combined_shared',
  'archived',
  'trashed',
]

export const OLD_USER_ACCOUNT_FILTERS: WallsFilter[] = ['all', 'templates', 'archived', 'trashed']
export const USER_ACCOUNT_FILTERS: WallsFilter[] = [
  'combined_recents',
  'made',
  'combined_shared',
  'archived',
  'trashed',
]
export const OLD_TENANT_USER_ACCOUNT_FILTERS: WallsFilter[] = ['all', 'made', 'templates', 'archived', 'trashed']
export const TENANT_USER_ACCOUNT_FILTERS: WallsFilter[] = ['combined_recents', 'all', 'made', 'archived', 'trashed']

export function filterDisplayName(
  filter: WallsFilter,
  viewableAndVisibleLibrariesCount: number = 0,
  isMobileViewport: boolean = false,
): string {
  const shouldShowOldCopy = isMobileViewport
  switch (filter) {
    case 'recents':
    case 'combined_recents':
    case 'writableRecents':
      return __('Recents')
    case 'all':
      if (shouldShowOldCopy) return __('All')
      if (currentHostname().endsWith(NATIVE_HOST) && viewableAndVisibleLibrariesCount === 0) return __('Made by me')
      return __('All padlets')
    case 'made':
      return isMobileViewport ? __('Made') : __('Made by me')
    case 'shared':
    case 'combined_shared':
      if (shouldShowOldCopy) return __('All')
      return viewableAndVisibleLibrariesCount === 0 ? __('Shared with me') : __('External')
    case 'writableShared':
      return __('Shared')
    case 'network':
    case 'writableNetwork':
      return __('Network')
    case 'liked':
    case 'writableLiked':
      return __('Liked')
    case 'archived':
      return __('Archived')
    case 'trashed':
      return __('Trashed')
    case 'favorites':
      return __('Favorites')
    case 'gallery':
      return __('Gallery')
    case 'private_walls':
      return __('Private')
    case 'templates':
      return __('Templates')
    // Fill these in when it's actually being used
    case 'portfolio':
    case 'contributed':
    default:
      return ''
  }
}

const GalleryTextToVizMap = {
  all: 'all',
  wall: 'grid',
  stream: 'stream',
  grid: 'matrix',
  shelf: 'shelf',
  map: 'map',
  canvas: 'free',
  timeline: 'timeline',
}

export const galleryTextArray = Object.keys(GalleryTextToVizMap)

export const galleryVizes = [
  { icon: 'gallery_outline', text: __('All'), viz: 'all' },
  { icon: 'wall_outline', text: __('Wall'), viz: 'grid' },
  { icon: 'stream_outline', text: __('Stream'), viz: 'stream' },
  { icon: 'grid_outline', text: __('Grid'), viz: 'matrix' },
  { icon: 'map_outline', text: __('Map'), viz: 'map' },
  { icon: 'canvas_outline', text: __('Canvas'), viz: 'free' },
  { icon: 'timeline_outline', text: __('Timeline'), viz: 'timeline_v2' },
]

export function filterIconName(filter: WallsFilter): string {
  const oldKnownIcons: Partial<Record<WallsFilter, string>> = {
    all: 'gallery_outline',
    recents: 'recent_outline',
    made: 'made_outline',
    shared: 'handshake_outline',
    liked: 'heart_outline',
    archived: 'archive_outline',
    trashed: 'delete',
    gallery: 'compass',
    private_walls: 'password',
    templates: 'sub_grid',
  }

  const knownIcons: Partial<Record<WallsFilter, string>> = {
    all: 'made_outline',
    made: 'made_outline',
    combined_recents: 'recent_outline',
    combined_shared: 'handshake_outline',
    liked: 'heart_outline',
    archived: 'archive_outline',
    trashed: 'delete',
  }

  return knownIcons[filter] ?? 'folder_outline'
}

export function getEmptyFilterCollectionCopy(filter: WallsFilter): [string, string] {
  switch (filter) {
    case 'recents':
    case 'combined_recents':
    case 'writableRecents':
      return [__('You haven’t recently viewed any padlets.'), __('It’s time to start browsing.')]
    case 'made':
      return [__("You haven't made any padlets yet."), __('Go create some magic.')]
    case 'shared':
    case 'combined_shared':
      return [__('You haven’t shared any padlets yet.'), __('Go make some friends.')]
    case 'combined_shared_user':
    case 'combined_shared_library':
    case 'writableShared':
      return [__('No shared padlets yet.'), __('Sharing is caring.')]
    case 'liked':
    case 'writableLiked':
      return [__("You haven't liked any padlets yet."), __('Go ahead, show your favourite padlets some love.')]
    case 'archived':
      return [__("You haven't archived any padlets yet."), __('It’s time to start packing.')]
    case 'trashed':
      return [__("You haven't trashed any padlets yet."), __('Recently deleted padlets will be listed here.')]
    case 'favorites':
      return [__('You haven’t liked any padlets yet.'), __('Go spread some love.')]
    case 'gallery':
      return ["Uh oh. You shouldn't be seeing this. The gallery is empty.", '']
    case 'all':
      return [__('No padlets yet.'), __('Why wait? Make something great.')]
    case 'private_walls':
      return [__("You don't have any private padlets."), __('Create something just for you.')]
    case 'templates':
      return [
        __('No templates yet!'),
        __('To create a template: open a padlet, click the 3-dot button, and select “Mark as template”.'),
      ]
    case 'portfolio':
    case 'contributed':
    case 'network':
    case 'writableNetwork':
    default:
      return ['', '']
  }
}

export function getEmptyFolderCollectionCopy(): [string, string] {
  return [__('There are no padlets in this folder.'), __("It's time to fill it up.")]
}

export function getEmptyGroupFolderCollectionCopy(): [string, string] {
  return [__("This group doesn't have any padlets."), __('Add this group as a collaborator on a padlet.')]
}

export function getEmptySearchCollectionCopy(): [string, string] {
  return [__('No results found.'), __('We hope you find what you are looking for.')]
}

const EMPTY_COLLECTION_COPIES = {
  [CollectionKeyTypes.Filter]: getEmptyFilterCollectionCopy,
  [CollectionKeyTypes.Search]: getEmptySearchCollectionCopy,
  [CollectionKeyTypes.FolderId]: getEmptyFolderCollectionCopy,
  [CollectionKeyTypes.GroupFolderId]: getEmptyGroupFolderCollectionCopy,
}

export const getEmptyCollectionCopy = (collectionKey: CollectionKey): [string, string] => {
  const getEmptyCollectionCopyFn = EMPTY_COLLECTION_COPIES[collectionKey.typeKey]
  return getEmptyCollectionCopyFn?.(collectionKey.indexKey as WallsFilter) ?? ['', '']
}

const COLLECTIONS_FETCH_LIMITS: Partial<Record<CollectionKey['typeKey'], Record<CollectionKey['indexKey'], number>>> = {
  filter: {
    // only fetch 50 of the most recently present walls
    combined_recents: 50,
  },
}

export function getCollectionFetchLimits({ typeKey, indexKey }: CollectionKey): number | undefined {
  return COLLECTIONS_FETCH_LIMITS[typeKey]?.[indexKey]
}

export function isCollectionSharedCollection(collectionKey: CollectionKey): boolean {
  return ['combined_shared_user', 'combined_shared_library'].includes(collectionKey.indexKey as string)
}

export function isCollectionShared(collectionKey: CollectionKey): boolean {
  return ['combined_shared_user', 'combined_shared_library', 'combined_shared'].includes(
    collectionKey.indexKey as string,
  )
}

export function isCollectionBookmark(collectionKey: CollectionKey): boolean {
  if (collectionKey.indexKey === 'favorites' || collectionKey.typeKey === 'folderId') return true
  return false
}

export function isCollectionSearch(collectionKey: CollectionKey): boolean {
  if (collectionKey.typeKey === 'search') return true
  return false
}

export function isCollectionInactiveWallsCollection(collectionKey: CollectionKey): boolean {
  return ['trashed', 'archived'].includes(collectionKey.indexKey as string)
}

// emojiRegex obtained from unicode site https://unicode.org/reports/tr51/#EBNF_and_Regex
export function extractEmojiFromFolderName(name: string): string {
  try {
    // eslint-disable-next-line prefer-regex-literals
    const emojiRegex = new RegExp(
      '^(\\p{RI}\\p{RI}|\\p{Extended_Pictographic}(\\p{Emoji_Modifier}|\\u{FE0F}\\u{20E3}?|[\\u{E0020}-\\u{E007E}]+\\u{E007F})?(\\u{200D}(\\p{RI}\\p{RI}|\\p{Emoji}(\\p{Emoji_Modifier}|\\u{FE0F}\\u{20E3}?|[\\u{E0020}-\\u{E007E}]+\\u{E007F})?))*)',
      'u',
    )
    const matches = name.match(emojiRegex)
    if (matches === null) return ''
    return matches[0]
  } catch {
    return ''
  }
}

// Returns false if any value of `hash` is blank. Logs bad values.
export function assertPresent(hash: Record<string, unknown>): boolean {
  let allPresent = true
  Object.entries(hash).forEach(([key, value]) => {
    if (value !== null && value !== undefined) return
    captureMessage(`Expected ${key} to have been set, but it was not.`)
    allPresent = false
  })
  return allPresent
}

// Logger helper
export function devLog(message: string, ...args): void {
  const logThisModule = false
  if (!logThisModule || !shouldLog) return
  console.log(`Pinia dash_collections_store: ${message}`, ...args) // goodcheck-disable-line
}

export function setAccountCollectionValue<T>(
  accountsCollectionsTreeRef: Record<Id, CollectionsTree<T>>,
  accountId: Id | string,
  collectionKey: CollectionKey,
  value: T,
): void {
  transformAccountCollectionValue(accountsCollectionsTreeRef, accountId, collectionKey, () => value)
}

export function transformAccountCollectionValue<T>(
  accountsCollectionsTree: Record<Id, CollectionsTree<T>>,
  accountId: Id | string | null,
  collectionKey: CollectionKey,
  transform: (oldValues: T | null) => T,
): void {
  if (accountId == null) return
  const collectionTree =
    accountsCollectionsTree[Number(accountId)] ?? vSet(accountsCollectionsTree, Number(accountId), {})
  transformCollectionValue(collectionTree, collectionKey, transform)
}

export function transformCollectionValue<T>(
  collectionTree: CollectionsTree<T>,
  collectionKey: CollectionKey,
  transform: (oldValue: T | null) => T,
): void {
  const oldCollectionTypeSubtree = collectionTree[collectionKey.typeKey] ?? {}
  const oldValue = oldCollectionTypeSubtree[collectionKey.indexKey] ?? null
  const newValue = transform(oldValue)
  const newCollectionTypeSubtree = { ...oldCollectionTypeSubtree, [collectionKey.indexKey]: newValue }
  vSet(collectionTree, collectionKey.typeKey, newCollectionTypeSubtree)
}

export function transformAccountsCollectionsValues<T>(
  accountsCollectionsTree: Record<Id, CollectionsTree<T>>,
  transform: (oldValues?: T) => T,
): void {
  Object.values(accountsCollectionsTree).forEach((collectionsValues) => {
    Object.values(collectionsValues).forEach((collectionTypeSubtree: CollectionsSubtree<T>) => {
      Object.entries(collectionTypeSubtree).forEach(([collectionKeyIndex, values]) => {
        const newValues = transform(values)
        vSet(collectionTypeSubtree, collectionKeyIndex, newValues)
      })
    })
  })
}

export function getAccountCollectionValue<T>(
  accountsCollectionsTree: Record<Id, CollectionsTree<T>>,
  accountId: Id | string | null,
  collectionKey: CollectionKey,
): T | null {
  if (accountId == null) return null
  accountId = Number(accountId)
  const collectionTree = accountsCollectionsTree[accountId]
  return collectionTree !== undefined ? getCollectionValue(collectionTree, collectionKey) : null
}

export function getCollectionValue<T>(collectionTree: CollectionsTree<T>, collectionKey: CollectionKey): T | null {
  const collectionTypeSubtree = collectionTree[collectionKey.typeKey]
  return collectionTypeSubtree !== undefined ? collectionTypeSubtree[collectionKey.indexKey] : null
}

export function isActiveCollectionKey(activeCollectionKey: CollectionKey, collectionKey: CollectionKey): boolean {
  return (
    activeCollectionKey.typeKey === collectionKey.typeKey && activeCollectionKey.indexKey === collectionKey.indexKey
  )
}

export function isActiveFolder(activeCollectionKey: CollectionKey, folderId: FolderId): boolean {
  return isActiveCollectionKey(activeCollectionKey, { typeKey: CollectionKeyTypes.FolderId, indexKey: folderId })
}

export function isActiveUserGroupFolder(activeCollectionKey: CollectionKey, userGroupId: UserGroupId): boolean {
  return isActiveCollectionKey(activeCollectionKey, {
    typeKey: CollectionKeyTypes.GroupFolderId,
    indexKey: userGroupId,
  })
}

export function isActiveFilter(activeCollectionKey: CollectionKey, filter: WallsFilter): boolean {
  return isActiveCollectionKey(activeCollectionKey, {
    typeKey: 'filter',
    indexKey: filter,
  })
}
